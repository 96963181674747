// Dependencies
import React from "react"
// Components
import Layout from "../components/Layout"

const StyelguidePage = () => (
  <Layout title="Styleguide">
    <h1>Start fleshing out design here...</h1>
  </Layout>
)

export default StyelguidePage
